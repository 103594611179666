

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from 'reactstrap';


const ScheduleModal = ({ isOpen, toggle, saveSchedule,editingNotification }) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [screenname, setscreenname] = useState('');
  const [Scheduletime, setScheduletime] = useState('');
  const [imageUrl, setImageUrl] = useState('');
const handleChange = (e) => {
    setScheduletime(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const handlescreenChange = (e) => {
    setscreenname(e.target.value);
  };
  const handleImageChange = (e) => {
    const url = e.target.value;
    console.log("Image URL:", url); 
    setImageUrl(url);
  };
  

  const handleSave = () => {
    saveSchedule(Scheduletime, title, message, screenname, imageUrl); // Ensure imageUrl is passed
  };
  


  const getCurrentSchedule = async () => {
    if (editingNotification) {
      setScheduletime(editingNotification.Scheduletime || "");
      setTitle(editingNotification.title || "");
      setMessage(editingNotification.message || "");
      setscreenname(editingNotification.screenname || "");
      setImageUrl(editingNotification.imageUrl || "");
    }
  };

   useEffect(() => {
    if (isOpen && editingNotification) {
      getCurrentSchedule(); 
    }
  }, [isOpen, editingNotification]); 

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Set Notification Schedule(In Minutes)</ModalHeader>

      <ModalBody>
        <Label className="floating-label" htmlFor="scheduleTime" style={{ color: "#000" }}>
          Enter schedule time in minutes
        </Label>
        <Input
          type="number"
          value={Scheduletime}
          onChange={handleChange}
          placeholder="Enter schedule time in minutes"
        />

        <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>
          Enter Title
        </Label>
        <Input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title for notification"
        />

        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>
          Enter Message
        </Label>
        <Input
          type="text"
          value={message}
          onChange={handleMessageChange}
          placeholder="Enter message for notification"
        />
        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>
          Enter Screen
        </Label>
        <Input
          type="text"
          value={screenname}
          onChange={handlescreenChange}
          placeholder="Enter message for notification"
        />
        <Label className="floating-label" htmlFor="message" style={{ color: '#000' }}>Enter Image URL</Label>
        <Input
          type="text"
          placeholder="Enter image URL"
          value={imageUrl}
          onChange={handleImageChange}
        />
        

      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={toggle}>Cancel</Button>
        <Button style={{ backgroundColor: "#CFD2D8" }} onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleModal;
